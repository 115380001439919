import Chart from 'chart.js/auto'

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('count-new') != null) {
    const context = document.getElementById('count-new').getContext('2d');
    const countNewGraph = new Chart(context, {
      type: 'bar',
      data: {
        labels: JSON.parse(context.canvas.dataset.labels),
        datasets: [
          {
            label: "車番数",
            backgroundColor: "rgb(255, 127, 80)",
            hoverBackgroundColor: "rgb(255, 218, 185)",
            data: JSON.parse(context.canvas.dataset.newData),
          },
        ]
      },
    });
  }
})
