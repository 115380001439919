import Chart from 'chart.js/auto'

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('count-heatmap') != null) {
    const mapHeight = 24;
    const mapWidth = 7;
    const weekdays = ['月', '火', '水', '木', '金', '土', '日']

    // データセットの生成
    const generateDatasets = function (heatmapData) {
      const datasets = []
      console.log(heatmapData);
      for (let i = 0; i < mapHeight; i++) {
        console.log(heatmapData[i])
        datasets.push({
          data: new Array(mapWidth).fill(1),
          borderWidth: 1,
          borderColor: "#FFFFFF",
          backgroundColor: heatmapData[i],
          stack: 'Stack 0',
        })
      }
      return datasets
    }

    // データラベルの生成
    const generateLabels = function () {
      let labels = []
      for (var i = 0; i < mapWidth; i++) {
        labels.push(weekdays[i])
      }
      return labels
    }

    const context = document.getElementById('count-heatmap').getContext('2d')
    const heatmapData = JSON.parse(context.canvas.dataset.heatmapData);
    const heatMap = new Chart(context, {
      type: 'bar',
      data: {
        yLabels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
        datasets: generateDatasets(heatmapData),
        labels: generateLabels(),
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          },
        },
        tooltip: {
          enabled: false,
        },
        scales: {
          xAxes: {
            gridLines: {
              color: '#FFFFFF',
            },
            barPercentage: 0.99,
            categoryPercentage: 0.99,
            stacked: true,
            ticks: {
              min: 0,
              display: true,
            }
          },
          yAxes: {
            type: 'category',
            gridLines: {
              color: '#FFFFFF',
              zeroLineWidth: 0
            },
            stacked: true,
            ticks: {
              min: 0,
              max: 24,
              stepSize: 1,
              display: true
            }
          }
        },
      }
    });
  }
})
