import Chart from 'chart.js/auto'

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('face-attribute-graph') != null) {
    const context = document.getElementById('face-attribute-graph').getContext('2d');
    const faceAttributeGraph = new Chart(context, {
      type: 'bar',
      data: {
        labels: JSON.parse(context.canvas.dataset.labels),
        datasets: [
          {
            label: "男性",
            backgroundColor: "rgb(65, 105, 225)",
            data: JSON.parse(context.canvas.dataset.menData),
          },
          {
            label: "女性",
            backgroundColor: "rgb(255, 20, 147)",
            data: JSON.parse(context.canvas.dataset.womenData),
          }
        ]
      },
    });  
  }  
})