// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery")
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "./business_intelligence/count_vehicles"
import "./business_intelligence/count_new"
import "./business_intelligence/count_repeat"
import "./business_intelligence/count_place"
import "./business_intelligence/count_heatmap"
import "./pedestrain_attributes/count_pedestrain_attributes"
import "./face_attributes"
import 'jquery'
import "./message"
import "./menu"
import "./sidebar"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
